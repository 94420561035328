import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoginUser from './pages/LoginUser';
import NotFound from './pages/NotFound';

import PrivateRouteUser from './PrivateRouteUser';
import { Provider } from './common/Context';

import AssessGuide from './pages/User/AssessGuide';
import Assess from './pages/User/Assess';
import AssessEnd from './pages/User/AssessEnd';
import AssessTimeout from './pages/User/AssessTimeout';

function App() {
  return (
    <Provider>
      <Router>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL + '/NotFound'}`} element={<NotFound />} />
          <Route path={`${process.env.PUBLIC_URL + '/'}`} element={<LoginUser />} />

          <Route path={`${process.env.PUBLIC_URL + '/:instDomain/loginUser'}`} element={<LoginUser />} />
          <Route path={`${process.env.PUBLIC_URL + '/:instDomain'}`} element={<LoginUser />} />
          <Route element={<PrivateRouteUser />}>
            {/* 대상자 > 검사안내 */}
            <Route path={`${process.env.PUBLIC_URL + '/:instDomain/AssessGuide'}`} element={<AssessGuide />} />
            {/* 대상자 > 검사 */}
            <Route path={`${process.env.PUBLIC_URL + '/:instDomain/Assess'}`} element={<Assess />} />
            {/* 대상자 > 검사종료 */}
            <Route path={`${process.env.PUBLIC_URL + '/:instDomain/AssessEnd'}`} element={<AssessEnd />} />
            {/* 대상자 > 검사종료 (30분 경과) */}
            <Route path={`${process.env.PUBLIC_URL + '/:instDomain/AssessTimeout'}`} element={<AssessTimeout />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
