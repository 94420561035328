import PropTypes from 'prop-types';
import React from 'react';
import ParticleTwo from '../particles/ParticleTwo';

const Breadcrumb = ({ instNm, title }) => {
  return (
    <div>
      <div
        className="ht__bradcaump__area"
        style={{
          background: `rgba(0, 0, 0, 0) url(${
            process.env.PUBLIC_URL + '/images/bg/7.jpg'
          }) repeat scroll center center/105% 100%`,
          height: '250px',
        }}
      >
        <div className="ht__bradcaump__container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="bradcaump__inner text-center">
                  <h2 className="bradcaump-title">
                    <span style={{ color: '#ffff00' }}>{instNm}</span> 온라인 인성검사
                  </h2>
                  <nav className="bradcaump-inner">
                    <span className="breadcrumb-item active">Home</span>
                    <span className="brd-separetor">/</span>
                    <span className="breadcrumb-item active">{title}</span>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* particle */}
        <ParticleTwo />
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string,
};

export default Breadcrumb;
