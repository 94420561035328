import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { useCustomContext } from '../common/Context';
import { UserApi } from '../common/UserApi';

import Header from '../containers/headers/Header';

const LayoutUser = ({ children, theme }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { instDomain } = useParams();
  const { showLoading, hideLoading, showMsg } = useCustomContext();
  const [projectUserInfo, setProjectUserInfo] = useState({
    ...JSON.parse(localStorage.getItem('projectUserInfo')),
    testStDt: localStorage.getItem('testStDt'),
  });
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    let timerInterval;
    if (localStorage.getItem('testStDt')) {
      const targetDate = new Date(projectUserInfo.testStDt);
      const targetTime = targetDate.getTime() + projectUserInfo.testTm * 60 * 1000;

      const updateTimer = () => {
        const now = new Date().getTime();
        const difference = targetTime - now;
        if (!localStorage.getItem('projectUserInfo')) {
          clearInterval(timerInterval);
          return;
        }

        if (difference > 0) {
          setTimeLeft(difference);
        } else {
          clearInterval(timerInterval);
          localStorage.removeItem('testStDt');
          handleTimeout();
          setTimeLeft(0);
        }
      };

      // 1초마다 타이머 업데이트
      timerInterval = setInterval(updateTimer, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  const preventGoBack = () => {
    window.history.pushState(null, '', location.href);
  };

  useEffect(() => {
    (() => {
      window.history.pushState(null, '', location.href);
      window.addEventListener('popstate', preventGoBack);
    })();

    return () => {
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);

  /**
   * 타이머 시간 변환
   */
  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleTimeout = async (e) => {
    try {
      showLoading();
      const res = await UserApi.post(`/api/user/updateTestEnDt`, {
        ...projectUserInfo,
        timeout: 'Y',
      });
      if (res.status == 200) {
        if (res.data.resultCode === 0) {
          navigate('/' + instDomain + '/AssessTimeout');
        } else {
          showMsg('error', res.data.resultMsg);
        }
      } else {
        showMsg('error', '오류가 발생하였습니다.');
      }
    } catch (err) {
      console.error(err);
      showMsg('error', '오류가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    showMsg('warning', '마우스 우클릭하는 행위는 부정행위로 불이익 받을 수 있습니다.');
  };

  const handleDragStart = (e) => {
    e.preventDefault();
    showMsg('warning', '마우스 드래그하는 행위는 부정행위로 불이익 받을 수 있습니다.');
  };

  return (
    <div
      onContextMenu={handleContextMenu}
      onDragStart={handleDragStart}
      style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}
    >
      <Fragment>
        <div className="loginInfo">
          {localStorage.getItem('projectUserInfo') && (
            <>
              <p>
                {projectUserInfo.userNm && projectUserInfo.userId && (
                  <>
                    응시자 이름 : {projectUserInfo.userNm} / ID : {projectUserInfo.userId}
                  </>
                )}
              </p>

              {projectUserInfo && projectUserInfo.testStDt && <h3>[ 잔여 검사시간 {formatTimeLeft(timeLeft)}]</h3>}
            </>
          )}
        </div>
        <Header theme={theme} />
        {children}
      </Fragment>
    </div>
  );
};

LayoutUser.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.string,
};

export default LayoutUser;
