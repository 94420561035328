import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Breadcrumb from '../components/breadcrumbs/Breadcrumb';
import LayoutUser from '../layouts/LayoutUser';
import { useCustomContext } from '../common/Context';
import { UserApi } from '../common/UserApi';

const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    setIsMobile(mobile);
  }, []);

  return isMobile;
};

const LoginRegister = () => {
  const navigate = useNavigate();
  const { instDomain } = useParams();
  const { showLoading, hideLoading, showMsg } = useCustomContext();

  const isMobile = useMobileDetect();

  const inputId = useRef(null);
  const inputPw = useRef(null);
  const [instNm, setInstNm] = useState(null);
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [open, setOpen] = useState(false);
  const [rsInfo, setRsInfo] = useState(null);

  useEffect(() => {
    localStorage.clear();
    domainCheck();
    inputId.current.focus();
  }, []);

  const domainCheck = async () => {
    try {
      showLoading();
      const res = await UserApi.post(`/api/common/selectDomainInfo`, { instDomain: '/' + instDomain });
      if (res.status == 200) {
        if (res.data.instNm) {
          setInstNm(res.data.instNm);
        } else {
          navigate('/NotFound');
        }
      } else {
        navigate('/NotFound');
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    if (rsInfo) {
      handleOpen();
    }
  }, [rsInfo]);

  useEffect(() => {
    if (!open) {
      setRsInfo(null);
    }
  }, [open]);

  const handleLogin = async () => {
    try {
      showLoading();
      const res = await UserApi.post(`/user/login`, { username: id, password: pw, instDomain: '/' + instDomain });
      if (res.status == 200) {
        if (res.data.resultCode === 0) {
          setRsInfo(res.data.resultData);
        } else {
          showMsg('error', res.data.resultMsg);
        }
      } else {
        showMsg('error', '오류가 발생하였습니다.');
      }
    } catch (err) {
      console.error(err);
      showMsg('error', '오류가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  const login = () => {
    localStorage.clear();
    localStorage.setItem('tokenType', rsInfo.grantType);
    localStorage.setItem('user-accessToken', rsInfo.accessToken);
    localStorage.setItem('instDomain', rsInfo.accessToken);
    localStorage.setItem('projectUserInfo', JSON.stringify(rsInfo.projectUserInfo));
    showMsg('success', '로그인이 정상적으로 처리 됩니다.');
    window.location.href = '/' + instDomain + '/AssessGuide';
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <LayoutUser theme="white">
        {/* breadcrumb */}
        <Breadcrumb instNm={instNm} title={'LOGIN'} />
        {isMobile ? (
          <>
            <p className="notice" style={{ marginTop: '25px' }}>
              본 검사 사이트는 모바일로 응시할 수 없습니다.
            </p>
            <p className="notice">컴퓨터로 검사 사이트를 이용해 주시기 바랍니다.</p>
          </>
        ) : (
          <>
            <p className="notice" style={{ marginTop: '25px', color: '#104862' }}>
              E-mail로 안내 받은 ID와 Password로 로그인 해 주십시오.
            </p>

            <p className="notice">
              본 온라인 인성검사는 PC 환경에서만 가능하며, 크롬(Chrome) 브라우저를 접속하여 응시하십시오.
            </p>
            <p className="notice">
              검사를 개시한 후에는 다시 검사를 할 수 없습니다. 사전에 검사를 실시할 수 있는 안정적인 인터넷 환경을
              확인하시고 로그인을 해 주십시오.
            </p>

            {/* login register content */}
            <div className="dg__account section-padding--xl" style={{ padding: '50px 0px' }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Tab.Container defaultActiveKey="login">
                      <Nav variant="pills" className="acount__nav justify-content-center">
                        <Nav.Item>
                          <Nav.Link eventKey="login">Login</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="login">
                          <div className="single__account">
                            <div className="input__box">
                              <span>ID</span>
                              <input type="text" onChange={(e) => setId(e.target.value)} ref={inputId} />
                            </div>
                            <div className="input__box">
                              <span>Password</span>
                              <input
                                type="password"
                                onChange={(e) => setPw(e.target.value)}
                                onKeyDown={(e) => onKeyDown(e)}
                                ref={inputPw}
                              />
                            </div>
                            <button className="account__btn" onClick={handleLogin}>
                              Login
                            </button>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>

                {rsInfo && (
                  <Fragment>
                    <Dialog
                      open={open}
                      onClose={handleOpen}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        style: {
                          width: '450px',
                          borderRadius: '40px',
                          border: '2px solid #1159bd',
                          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.6)',
                        },
                      }}
                    >
                      <DialogTitle
                        style={{ marginTop: '20px', fontWeight: 'bold', textAlign: 'center' }}
                        id="alert-dialog-title"
                      >
                        응시자 인적정보 확인
                      </DialogTitle>
                      <DialogContent>
                        <div className="confirmText">
                          <p>
                            기관명 : <strong>{rsInfo.projectUserInfo.instNm}</strong>
                          </p>
                          <p>
                            채용공고명 : <strong>{rsInfo.projectUserInfo.jobTitle}</strong>
                          </p>
                          <p>
                            채용분야명 : <strong>{rsInfo.projectUserInfo.jobField}</strong>
                          </p>
                          <p>
                            수험번호 : <strong>{rsInfo.projectUserInfo.userNum}</strong>
                          </p>
                          <p>
                            이름 : <strong>{rsInfo.projectUserInfo.userNm}</strong>
                          </p>
                          <p>
                            전화번호 뒤4자리: <strong>{rsInfo.projectUserInfo.userPhone}</strong>
                          </p>
                        </div>
                        <br />
                        <div className="right">
                          <p>
                            <strong>본인의 정보가 맞으면 '예'를 다르면 '아니요'를 누르세요.</strong>
                          </p>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button style={{ color: 'red', fontSize: '15px', marginBottom: '15px' }} onClick={handleOpen}>
                          아니요
                        </Button>
                        <Button
                          style={{ fontSize: '15px', marginRight: '20px', marginBottom: '15px' }}
                          onClick={login}
                          autoFocus
                        >
                          예
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                )}
              </div>
            </div>
          </>
        )}
      </LayoutUser>
    </Fragment>
  );
};

export default LoginRegister;
