import axios from 'axios';

export const UserApi = axios.create({
  baseURL: '/',
  headers: { 'Content-Type': 'application/json' },
  timeout: 5000,
  // withCredentials: true,
});

UserApi.interceptors.request.use((config) => {
  const TOKEN_TYPE = localStorage.getItem('tokenType');
  const TOKEN = localStorage.getItem('user-accessToken');
  if (TOKEN) {
    config.headers['Authorization'] = `${TOKEN_TYPE} ${TOKEN}`;
  }
  return config;
});

UserApi.interceptors.response.use(
  (response) => {
    if (response.headers['authorization']) {
      localStorage.setItem('accessToken', response.headers['authorization'].slice(7));
    }
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      // window.alert('토큰 만료');
      localStorage.clear();
      window.location.href = `/`;
    } else if (error.response.status === 403) {
      // window.alert('권한없는 메뉴 접근');
      window.location.href = `/`;
    }
    return Promise.reject(error);
  }
);
