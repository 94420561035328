import React, { createContext, useContext, useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

const Context = createContext();

export const Provider = ({ children }) => {
  const [progress, setProgress] = useState(false);
  const [projectInfo, setProjectInfo] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alerts, setAlerts] = useState([]);

  const showLoading = () => {
    setProgress(true);
  };

  const hideLoading = () => {
    setProgress(false);
  };

  const getProject = () => {
    return projectInfo;
  };

  const setProject = (e) => {
    setProjectInfo(e);
  };

  const showMsg = (severity, content) => {
    const newAlert = { id: new Date().getTime(), severity, content };
    setAlerts((prevAlerts) => {
      const updatedAlerts = [...prevAlerts, newAlert];
      if (updatedAlerts.length > 2) {
        updatedAlerts.shift();
      }
      return updatedAlerts;
    });

    setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== newAlert.id));
    }, 3000);
  };

  return (
    <Context.Provider value={{ showLoading, hideLoading, getProject, setProject, showMsg }}>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.4) !important',
        }}
        open={progress}
      >
        <CircularProgress color="inherit" size={100} />
      </Backdrop>
      <Stack
        sx={{ position: 'fixed', bottom: '0px', left: '50%', transform: 'translate(-50%, 0)', zIndex: 9999 }}
        spacing={2}
      >
        {alerts.map((alert) => (
          <Paper key={alert.id} elevation={4} style={{ marginBottom: '10px' }}>
            <Alert key={alert.id} severity={alert.severity}>
              {alert.content}
            </Alert>
          </Paper>
        ))}
      </Stack>
      {children}
    </Context.Provider>
  );
};

export const useCustomContext = () => {
  return useContext(Context);
};
