import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';
import LayoutUser from '../../layouts/LayoutUser';
import { Button } from '@mui/material';

import { useCustomContext } from '../../common/Context';
import { UserApi } from '../../common/UserApi';

const AssessGuide = () => {
  const navigate = useNavigate();
  const { instDomain } = useParams();
  const { showLoading, hideLoading, showMsg } = useCustomContext();
  const [projectUserInfo, setProjectUserInfo] = useState(JSON.parse(localStorage.getItem('projectUserInfo')));

  const handleStart = async () => {
    try {
      showLoading();
      const res = await UserApi.post(`/api/user/startAssess`, projectUserInfo);
      if (res.status == 200) {
        if (res.data.resultCode === 0) {
          localStorage.setItem('testStDt', res.data.resultData);
          window.location.href = '/' + instDomain + '/Assess';
        } else {
          showMsg('error', res.data.resultMsg);
        }
      } else {
        showMsg('error', '에러가 발생하였습니다.');
      }
    } catch (err) {
      showMsg('error', '에러가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  return (
    <Fragment>
      <LayoutUser theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="검사안내" />
        {/* contact page content */}
        <section className="conact__area pt--140">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="assessNotice">
                  <h3 style={{ marginLeft: '15px', color: '#002060' }}> ■ 검사 방법 안내</h3>
                  <ul>
                    <li>
                      인터넷 브라우저 창의 크기를 최대 크기로 변경한 후 아래의{' '}
                      <span className="blue">「검사시작」</span> 버튼을 눌러 검사를 시작합니다.
                    </li>
                    <li>
                      검사 마지막 화면에서 <span className="blue">「검사결과 제출」</span> 버튼을 누르셔야 최종 검사
                      결과가 제출됩니다.
                    </li>
                    <li>
                      검사시간은 <span className="red">{projectUserInfo.testTm}분</span>이며, 총 응답하는 문항수는{' '}
                      <span className="red">{projectUserInfo.questionCnt}</span> 문항 입니다.
                    </li>
                    <li>
                      검사시작 후에는 중단할 수 없으며 <span className="red">{projectUserInfo.testTm}분</span>이
                      경과되면, 검사는 자동으로 종료되므로 <span className="red">{projectUserInfo.testTm}분</span> 이내
                      모든 문항에 응답해야 합니다.
                    </li>
                  </ul>

                  <div className="noticeBorder"></div>

                  <h3 style={{ marginLeft: '15px', color: '#002060' }}> ■ 주의 사항 안내</h3>
                  <ul>
                    <li>
                      검사는 반드시 본인이 실시해야 합니다. 타인이 대신 검사를 진행한 경우, 채용이 취소될 수 있습니다.
                    </li>
                    <li>
                      문항을 읽고 본인 생각대로 솔직하게 응답해 주십시오. (본인의 생각이 아닌 바람직성을 감안한 허위
                      반응 시 불이익을 받을 수 있습니다.)
                    </li>
                    <li>
                      너무 깊이 생각하면 결정할 수 없는 경우가 있으므로, 가벼운 마음으로 한번 읽은 후 직관적으로 응답해
                      주십시오.
                    </li>
                    <li>모든 문항에 응답을 하셔야 됩니다.</li>
                    <li>화면별로 각 문항에 응답을 선택하면 응답 결과가 자동으로 저장됩니다.</li>
                    <li>
                      한 화면의 모든 문항에 응답한 후 <span className="blue">「다음」</span> 버튼을 누르면, 다음
                      화면으로 이동됩니다. <span className="blue">「이전」</span> 버튼은 이전 화면으로 이동되고, 저장된
                      응답 결과를 확인 할 수 있습니다.
                    </li>
                    <li>검사종료시까지 인터넷 브라우저 창의 크기를 최대크기로 유지하여야 합니다.</li>
                    <li>인터넷 브라우저의 뒤로가기 기능은 사용할 수 없습니다.</li>
                  </ul>
                  <div className="startButton">
                    <Button
                      variant="contained"
                      size="large"
                      style={{ width: '150px', height: '50px', fontSize: '20px' }}
                      onClick={handleStart}
                    >
                      검사시작
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutUser>
    </Fragment>
  );
};

export default AssessGuide;
