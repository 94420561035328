import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LayoutUser from '../../layouts/LayoutUser';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';
import { Button, Box } from '@mui/material';

import { useCustomContext } from '../../common/Context';
import { UserApi } from '../../common/UserApi';

const AssessEnd = () => {
  const { instDomain } = useParams();
  const { showLoading, hideLoading, showMsg } = useCustomContext();

  const [projectUserInfo, setProjectUserInfo] = useState(JSON.parse(localStorage.getItem('projectUserInfo')));
  const [endInfo, setEndInfo] = useState(null);

  useEffect(() => {
    selectEndInfo();
    localStorage.clear();
  }, []);

  const selectEndInfo = async () => {
    try {
      showLoading();
      const res = await UserApi.post(`/api/user/selectEndInfo`, projectUserInfo);
      if (res.status == 200) {
        if (res.data.resultCode === 0) {
          setEndInfo(res.data.resultData);
        } else {
          showMsg('error', res.data.resultMsg);
        }
      } else {
        showMsg('error', '오류가 발생하였습니다.');
      }
    } catch (err) {
      console.error(err);
      showMsg('error', '오류가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  /**
   * 확인 시 localStorage 초기화 및 초기화면 이동
   */
  const handleConfirm = () => {
    window.location.href = '/' + instDomain;
  };

  return (
    <Fragment>
      <LayoutUser theme="white">
        <Breadcrumb title="검사종료" />

        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 9999,
          }}
        ></div>
        <section className="assessSection section-padding--xl">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="endNotice">
                  <h2>수고하셨습니다.</h2>
                  <h2>온라인 인성검사가 종료되었습니다.</h2>
                  <div className="noticeBorder" />
                  {endInfo && (
                    <div className="endInfo">
                      <h5>
                        <strong>검사시작시간: </strong>&nbsp; {endInfo.testStDt}
                      </h5>
                      <h5>
                        <strong>검사완료시간: </strong>&nbsp; {endInfo.testEnDt}
                      </h5>
                      <h5>
                        <strong>검사소요시간: </strong>&nbsp; {endInfo.testDiffDt}
                      </h5>
                      <h5>
                        <strong>응답 문항 수: </strong>&nbsp;&nbsp;&nbsp; {endInfo.rsCnt} 문항
                      </h5>
                      <br />
                      <br />
                      <h5>
                        <strong>입력하신 응답결과는 정상적으로 제출되었습니다.</strong>
                      </h5>
                      <br />
                      <h5>
                        <strong>[확인] 버튼을 누르면 자동 종료 됩니다.</strong>
                      </h5>
                    </div>
                  )}
                  <Box alignItems="center">
                    <Button
                      className="childButton"
                      variant="contained"
                      size="large"
                      sx={{ marginTop: '50px' }}
                      onClick={(e) => handleConfirm()}
                    >
                      확인
                    </Button>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutUser>
    </Fragment>
  );
};

export default AssessEnd;
