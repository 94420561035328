import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Header = ({ theme }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const header = document.querySelector('header');
    setHeaderTop(header.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header className={`dg__header header--absolute space-right-left`}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-2 col-xl-2 col-6"></div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  theme: PropTypes.string,
};

export default Header;
