import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useParams, useNavigate } from 'react-router-dom';
import LayoutUser from '../layouts/LayoutUser';
import Breadcrumb from '../components/breadcrumbs/Breadcrumb';

const NotFound = () => {
  const navigate = useNavigate();
  const { instDomain } = useParams();

  useEffect(() => {
    if (instDomain) navigate('/' + instDomain);
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <title>Howard | Not Found</title>
        <meta name="description" content="404 page of React JS Crypto Currency Template." />
      </MetaTags>
      <LayoutUser theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="NOT FOUND" />
        {/* not found page content */}
        <div className="errorPageWrapper d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-9 m-auto text-center">
                <div className="errorContentCentered d-flex align-items-center justfy-content-center">
                  <div className="errorPageContentWrap">
                    <h2>진행 가능한 검사가 없습니다.</h2>
                    <h3>응시일정 및 접속주소를 확인 후 다시 시도해주세요.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutUser>
    </Fragment>
  );
};

export default NotFound;
