import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import LayoutUser from '../../layouts/LayoutUser';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';
import { Grid, List, ListItem, ListItemText, Divider, Button, Box, Radio } from '@mui/material';
import { pink } from '@mui/material/colors';

import { useCustomContext } from '../../common/Context';
import { UserApi } from '../../common/UserApi';

const Assess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { instDomain } = useParams();
  const { showLoading, hideLoading, showMsg } = useCustomContext();
  const [projectUserInfo, setProjectUserInfo] = useState(JSON.parse(localStorage.getItem('projectUserInfo')));
  const [page, setPage] = useState(1);
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    selectQuestionList();

    (() => {
      window.addEventListener('beforeunload', preventClose);
    })();

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, []);

  const ws = useRef(null);

  useEffect(() => {
    const connectWebSocket = () => {
      ws.current = new WebSocket('ws://' + window.location.hostname + ':81/ws');
      // ws.current = new WebSocket('ws://' + window.location.hostname + ':8080/ws');

      ws.current.onopen = () => {
        console.log('WebSocket connection established');
      };

      ws.current.onmessage = (message) => {
        if (message.data === projectUserInfo.projectCd) {
          handleSubmit();
        }
      };

      ws.current.onclose = () => {
        console.log('WebSocket connection closed');
        setTimeout(() => {
          connectWebSocket();
        }, 200);
      };
    };

    const keepAlive = () => {
      if (ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify({ type: 'ping' }));
      }
      setTimeout(keepAlive, 30000); // 30초마다 ping 전송
    };

    connectWebSocket();
    keepAlive();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  /**
   * 브라우저종료 및 새로고침 시 경고 메세지 표출
   * @param {*} e
   */
  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const selectQuestionList = async () => {
    try {
      showLoading();
      const res = await UserApi.post(`/api/user/selectQuestionList`, projectUserInfo);
      if (res.status == 200) {
        if (res.data.resultCode === 0) {
          setQuestionList(
            res.data.resultData.list.map((data) => ({
              ...data,
              no: data.no + (res.data.resultData.page - 1) * 10,
            }))
          );
          setPage(res.data.resultData.page);
        } else {
          showMsg('error', res.data.resultMsg);
        }
      } else {
        showMsg('error', '오류가 발생하였습니다.');
      }
    } catch (err) {
      console.error(err);
      showMsg('error', '오류가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  const handlePage = async (e) => {
    try {
      showLoading();
      const firstNo = (page - 1 + e) * 10;
      const res = await UserApi.post(`/api/user/insertQuestionRs`, {
        ...projectUserInfo,
        firstNo: firstNo,
        list: questionList,
      });
      if (res.status == 200) {
        if (res.data.resultCode === 0) {
          setQuestionList(
            res.data.resultData.map((data) => ({
              ...data,
              no: data.no + firstNo,
            }))
          );
          setPage(page + e);
        } else {
          showMsg('error', res.data.resultMsg);
        }
      } else {
        showMsg('error', '오류가 발생하였습니다.');
      }
    } catch (err) {
      console.error(err);
      showMsg('error', '오류가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  const handleSubmit = async (e) => {
    try {
      showLoading();
      const res = await UserApi.post(`/api/user/updateTestEnDt`, {
        ...projectUserInfo,
        list: questionList,
      });
      if (res.status == 200) {
        if (res.data.resultCode === 0) {
          navigate('/' + instDomain + '/AssessEnd');
        } else {
          showMsg('error', res.data.resultMsg);
        }
      } else {
        showMsg('error', '오류가 발생하였습니다.');
      }
    } catch (err) {
      console.error(err);
      showMsg('error', '오류가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  const handleChange = (index, value) => {
    setQuestionList(questionList.map((data, i) => (i === index ? { ...data, answer: value } : data)));
  };

  const controlProps = (index, item) => ({
    checked: questionList[index].answer === item,
    onChange: () => handleChange(index, item),
    value: item,
    name: `color-radio-button-demo-${index}`,
    inputProps: { 'aria-label': item },
  });

  const style = {
    p: 0,
    width: '100%',
    maxWidth: '800px',
    minWidth: '800px',
    marginTop: '58px',
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    marginRight: '20px',
  };
  const styleRadio = {
    p: 0,
    width: '300px',
    maxWidth: '300px',
    minWidth: '300px',
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
  };

  return (
    <Fragment>
      <LayoutUser theme="white">
        <Breadcrumb title="검사진행" />
        <section className="assessSection section-padding--xl">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{ minWidth: '800px' }}>
                <div style={{ textAlign: 'center', fontSize: '20px' }}>
                  <strong>
                    {page} 페이지 / {projectUserInfo.questionCnt / 10} 페이지
                  </strong>
                </div>
                <div className="progressNotice">
                  <ul>
                    <li>
                      각 문항의 지문을 읽고 본인의 생각이나 경험을 바탕으로 판단하여 5점 척도 중 하나로 응답합니다.
                    </li>
                    <li>모든 문항에 응답하면 다음 페이지로 갈 수 있습니다.</li>
                  </ul>
                  <div className="noticeBorder" style={{ marginTop: '10px', marginBottom: '10px' }} />
                </div>

                <div className="assessBorder"></div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} md={9} style={{ maxWidth: '800px', marginRight: '20px' }}>
                    <List sx={style} aria-label="mailbox folders">
                      {questionList &&
                        questionList.map((question, index) => (
                          <Fragment key={index}>
                            <ListItem className="listItem">
                              <ListItemText primary={`${question.no}. ${question.questionNm}`} />
                            </ListItem>
                            {index < questionList.length - 1 && <Divider component="li" />}
                          </Fragment>
                        ))}
                    </List>
                  </Grid>
                  <Grid item xs={3}>
                    <List sx={styleRadio} aria-label="mailbox folders">
                      <ListItem className="listItem">
                        <ListItemText
                          primary={
                            <div className="lsitText">
                              전혀
                              <br />
                              아니다
                            </div>
                          }
                        />
                        <ListItemText primary={<span className="lsitText">아니다</span>} />
                        <ListItemText
                          primary={
                            <div className="lsitText">
                              보통
                              <br />
                              이다
                            </div>
                          }
                        />
                        <ListItemText primary={<span className="lsitText">그렇다</span>} />
                        <ListItemText
                          primary={
                            <div className="lsitText">
                              매우
                              <br />
                              그렇다
                            </div>
                          }
                        />
                      </ListItem>

                      {questionList &&
                        questionList.map((question, questionIndex) => (
                          <Fragment key={questionIndex}>
                            <Divider component="li" />
                            <ListItem>
                              <div>
                                <Radio {...controlProps(questionIndex, 1)} sx={{ marginRight: 1.3 }} />
                                <Radio
                                  {...controlProps(questionIndex, 2)}
                                  color="secondary"
                                  sx={{ marginRight: 1.3 }}
                                />
                                <Radio {...controlProps(questionIndex, 3)} color="success" sx={{ marginRight: 1.3 }} />
                                <Radio {...controlProps(questionIndex, 4)} color="default" sx={{ marginRight: 1.3 }} />
                                <Radio
                                  {...controlProps(questionIndex, 5)}
                                  sx={{
                                    marginRight: 1.3,
                                    color: pink[800],
                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }}
                                />
                              </div>
                            </ListItem>
                          </Fragment>
                        ))}
                    </List>
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    className="childButton"
                    variant="contained"
                    size="large"
                    sx={{ margin: '50px 100px 0 0' }}
                    disabled={page <= 1}
                    onClick={(e) => handlePage(-1)}
                  >
                    이전
                  </Button>
                  {page < projectUserInfo.questionCnt / 10 ? (
                    <Button
                      className="childButton"
                      variant="contained"
                      size="large"
                      sx={{ marginTop: '50px' }}
                      disabled={!questionList.every((data) => data.answer > 0)}
                      onClick={(e) => handlePage(1)}
                    >
                      다음
                    </Button>
                  ) : (
                    <Button
                      className="childButton"
                      variant="contained"
                      size="large"
                      sx={{ marginTop: '50px' }}
                      disabled={!questionList.every((data) => data.answer > 0)}
                      onClick={(e) => handleSubmit()}
                    >
                      검사결과제출
                    </Button>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </section>
      </LayoutUser>
    </Fragment>
  );
};

export default Assess;
